import styled from 'styled-components';
import { SectionHeader, TextContent } from './components';
import { posters } from './data/posters';
import { epkLinks } from './data/epkLinks';

export const EPKTourPack = () => {
  const tourAndRiders = epkLinks.filter((i) => i.id.match(/tour/gi));
  const handleClickLogo = (imgLink) => {
    window.open(imgLink, '_blank').focus();
  };

  return (
    <div>
      <SectionHeader style={{ position: 'relative' }}>
        TOUR PACK
        {window.location.href.includes('/epk/tour') && (
          <BackToEPK href="/epk">back to full EPK page</BackToEPK>
        )}
      </SectionHeader>
      <TextContent center>
        {tourAndRiders.map((item) => (
          <p key={item.link}>
            <a
              style={{ color: 'black' }}
              href={item.link}
              title={item.label}
              target="_blank"
              rel="noreferrer"
            >
              {item.label}
            </a>
          </p>
        ))}
        <p>
          All our poster art is made by{' '}
          <a
            style={{ color: 'black' }}
            href="http://ultragrim.tumblr.com"
            target="_blank"
            rel="noreferrer"
          >
            Ultragrim
          </a>
          .
        </p>
        <p>Click on each poster to preview.</p>
        <PostersSection>
          {posters.map((image) => (
            <PosterItem
              key={image.src}
              onClick={() => handleClickLogo(image.src)}
            >
              <Image
                key={image.src}
                src={image?.display || image.src}
                alt={image.alt}
              />
              <p>{image.alt}</p>
            </PosterItem>
          ))}
        </PostersSection>
      </TextContent>
    </div>
  );
};

const BackToEPK = styled.a`
  @media only screen and (max-width: 768px) {
    width: 4ch;
    word-break: break-all;
    overflow: hidden;
    text-overflow: hidden;
    white-space: nowrap;
  }

  color: black;
  position: absolute;
  right: 0px;
  bottom: 2px;
  font-weight: normal;
  font-size: 14px;
`;

const PostersSection = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const PosterItem = styled.div`
  flex: 1;
  cursor: pointer;

  > p {
    margin-top: 0;
    font-size: 0.75rem;
  }

  :hover {
    > p {
      text-decoration: underline;
    }
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

const Image = styled.img`
  max-height: 700px;
  max-width: 300px;

  @media only screen and (max-width: 768px) {
    max-height: 300px;
  }
`;
