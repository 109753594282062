import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { menuItems } from './data/menuItems';
import { menuItemsEPK } from './data/menuItemsEPK';
import { HamburgerMenu } from './icons';

export const Masthead = ({ isEPK }) => {
  const menuItemsToUse = isEPK ? menuItemsEPK : menuItems;
  const [width, setWidth] = useState(window.innerWidth);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const toggleMenu = () => {
    setMenuOpen((prevState) => !prevState);
  };

  return (
    <Container>
      <a href="/">
        <ALogo src="/new_a_olive.png" />
      </a>
      {isMobile ? (
        <>
          <IconButton onClick={toggleMenu}>
            <HamburgerMenu />
          </IconButton>
          <MobileMenuContainer open={menuOpen}>
            <ul
              style={{
                listStyle: 'none',
                marginBlock: '8px',
                paddingLeft: '4px',
              }}
            >
              {menuItemsToUse.map((item) => (
                <MenuListItem key={item.id} onClick={toggleMenu}>
                  <DesktopMenuItem href={item.id}>{item.label}</DesktopMenuItem>
                </MenuListItem>
              ))}
            </ul>
          </MobileMenuContainer>
        </>
      ) : (
        <div style={{ padding: '4px 0' }}>
          {menuItemsToUse.map((item, index) => (
            <span key={item.id}>
              <DesktopMenuItem href={item.id}>{item.label}</DesktopMenuItem>
              {index !== menuItemsToUse.length - 1 && (
                <span style={{ margin: '0 6px' }}>{` | `}</span>
              )}
            </span>
          ))}
        </div>
      )}
    </Container>
  );
};

const ALogo = styled.img`
  height: 32px;
  float: left;
`;

const Container = styled.div`
  height: 2rem;
  display: block;
  position: sticky;
  top: 0;
  background-color: #ffffff;
  text-align: right;
  padding: 0.25rem 2rem;
  box-shadow: 2px 7px 8px -10px #adadad;

  @media only screen and (max-width: 768px) {
    padding: 0.25rem 1rem;
  }
`;

const IconButton = styled.button`
  padding: 0.25rem 0.125rem;
  border: none;
  background: transparent;
  float: right;

  > svg {
    fill: #bf982a;
  }
`;

const DesktopMenuItem = styled.a`
  text-decoration: none;
  color: black;

  &:hover {
    text-decoration: underline;
  }
`;

const MobileMenuContainer = styled.div`
  display: ${({ open }) => (open ? 'block' : 'none')};
  float: right;
  width: 150px;
  background-color: #ffffff;
  border: 1px solid lightgrey;
  position: absolute;
  top: 2.5rem;
  right: 1rem;
  padding: 0 0.625rem;
  box-shadow: 8px 0px 10px -12px black;
`;

const MenuListItem = styled.li`
  padding: 8px 0;
`;
